<template>
  <div class="page" :class="{'ratio': devicePixelRatio === 2}">
    <div class="block bar">
      <div class="bar_content">
        <div class="bar_value">
          <div class="title">OUR VISION</div>
          <div class="text">Papichou exists to solve the critical issues facing our financial clients, both large and small. We offer customized services and partnership to assess the creditworthiness of individuals.</div>
          <div class="hint">We are a fintech provider of alternative lending solutions for Credit Scoring, Fraud Detection & Identity Check. It brings innovations into emerging markets by supplying mobile financial services to the underbanked. The company’s products use cutting-edge algorithms, machine-learning & data sciences to provide real-time Credit Scoring & Lending-As-A-Service (LaaS) solutions to expand client basis for diverse business targets.</div>
        </div>
      </div>
      <div class="bar_img">
        <img v-if="devicePixelRatio == 2" src="@/assets/img/our_banner@2x.png">
        <img v-else src="@/assets/img/our_banner.png">
      </div>
    </div>

    <div class="block form" v-loading="loading">
      <div class="block_box">
        <div class="title">CONTACT US</div>
        <div class="line"></div>
        <el-form :model="form" ref="form" :rules="rules" class="form_content">
          <div class="input">
            <span class="required">Name：</span>
            <el-form-item prop="name">
              <el-input
                placeholder="Please enter"
                v-model="form.name">
              </el-input>
            </el-form-item>
          </div>
          <div class="input">
            <span class="required">Email：</span>
            <el-form-item prop="email">
              <el-input
                placeholder="Please enter"
                v-model="form.email">
              </el-input>
            </el-form-item>
          </div>
          <div class="input">
            <span class="required">Subject：</span>
            <el-form-item prop="subject">
              <el-input
                placeholder="Please enter"
                v-model="form.subject">
              </el-input>
            </el-form-item>
          </div>
          <div class="input">
            <span class="required">Type your message here：</span>
            <el-form-item prop="content">
              <el-input
                type="textarea"
                :rows="10"
                placeholder="Please enter"
                v-model="form.content">
              </el-input>
            </el-form-item>
          </div>
          <div class="input">
            <span class="required">Type your message here：</span>
            <div class="radio">
              <el-form-item prop="type">
                <el-radio-group v-model="form.type">
                  <el-radio label="Investment enquiries">Investment enquiries</el-radio>
                  <el-radio label="Solution enquiries">Solution enquiries</el-radio>
                  <el-radio label="About our company (media & PR)">About our company (media & PR)</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
          <el-button type="primary" @click="onSubmit">Submit</el-button>
        </el-form>
      </div>
    </div>

  </div>
</template>

<script>
import { contactUs } from '../plugins/api';

export default {
  name: 'ContactUs',
  data() {
    return {
      devicePixelRatio: window.devicePixelRatio,
      loading: false,
      form: {
        name: '',
        email: '',
        subject: '',
        content: '',
        type: '',
      },
      rules: {
        name: { required: true, message: 'Please enter', trigger: 'change' },
        email: { required: true, message: 'Please enter', trigger: 'change' },
        subject: { required: true, message: 'Please enter', trigger: 'change' },
        content: { required: true, message: 'Please enter', trigger: 'change' },
        type: { required: true, message: 'Please select', trigger: 'change' },
      },
    };
  },
  methods: {
    async onSubmit() {
      const valid = await this.$refs.form.validate();
      if (!valid) return;
      this.loading = true;
      /*
      await this.$axios
        .post(contactUs, this.form)
        .then((e) => {
          if (e.status.code === '000') {
            this.$refs.form.resetFields();
            this.$message({
              message: e.status.msg,
              type: 'success',
            });
          } else {
            this.$message.error(e.status.msg);
          }
        })
        .catch(() => {}); * */
      this.$message({
        message: 'submit success!',
        type: 'success',
      });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin pixel-ratio() {}

.page {
  @include pixel-ratio();
  .block {
    text-align: left;
    .title {
      font-size: 32px;
      font-weight: 700;
      color: #333333;
    }
    .text {
      font-size: 14px;
      color: #333333;
      padding: 10px 0px;
    }
    .hint {
     font-size: 13px;
     color: #333333;
     padding: 10px 0px;
    }
    .line {
      height: 0.5px;
      background: #CCCCCC;
    }
    .el-button {
      margin-top: 30px;
    }
    .el-form-item {
      display: inline-block;
    }
    &_box {
      max-width: 1200px;
      margin: 0 auto;
    }
    &.bar {
      position: relative;
      background: white;
      .bar_img {
        width: 100%;
        img {
          width: 100%;
        }
      }
      .bar_content {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        .bar_value {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          padding: 0px 10px;
          max-width: 1200px;
          margin: 0 auto;
          .hint {
            max-width: 500px;
          }
        }
      }
    }
    &.form {
      margin-top: 40px;
      .block_box {
        padding: 10px;
      }
      .form_content {
        text-align: center;
        padding: 20px;
        .input {
          display: inline-block;
          width: 100%;
          text-align: center;
          span {
            min-width: 155px;
            display: inline-block;
            text-align: right;
            font-size: 12px;
            color: #666666;
          }
          .el-input {
            vertical-align: middle;
            margin-left: 10px;
            width: 400px;
          }
          .el-textarea {
            width: 400px;
            vertical-align: top;
            margin-left: 4px;
          }
          .radio {
            display: inline-block;
            width: 400px;
            vertical-align: top;
            text-align: left;
            .el-radio {
              display: block;
              text-align: left;
              margin-bottom: 12px;
            }
          }
        }
        .el-button {
          min-width: 350px;
          margin-top: 40px;
        }
      }
    }

  }

  @media screen and (max-width: 800px) {
    .block {
      text-align: center;
      .block_box {
        display: inline-block;
      }
      .el-form-item {
        display: block;
      }
      &.bar {
        min-height: 600px;
        .bar_img {
          position: absolute;
          top: 150px;
          bottom: 0px;
          left: 0px;
          right: 0px;
          &::before {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: linear-gradient(0deg,rgba(0,0,0,0.00)0%,#ffffff 100%);
          }
          img {
            height: 100%;
            width: auto;
            position: absolute;
            right: 0px;
            transform: translateX(22%);
          }
        }
        .title {
          font-size: 26px;
        }
        .el-button {
          position: absolute;
          bottom: 28px;
          left: 50%;
          transform: translateX(-50%);
        }
        .bar_content {
          padding-top: 30px;
          padding-bottom: 20px;
          .bar_value {
            position: initial;
            top: 10px;
            transform: none;
          }
        }
      }

      &.form {
        .form_content {
          margin-top: 0px;
          padding: 0px 10px;
          padding-top: 20px;
          padding-bottom: 30px;
          .input {
            width: 100%;
            padding: 0px;
            text-align: left;
            span {
              text-align: left;
              margin-bottom: 8px;
            }
            .el-input {
              margin-left: 0px;
              width: 100%;
            }
            .el-textarea {
              margin-left: 0px;
              width: 100%;
            }
            .radio {
              margin-left: 0px;
              width: 100%;
            }
          }
          .el-button {
            min-width: 80%;
            margin-top: 40px;
          }
        }
      }

    }
  }
}
</style>
